<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  data () {
    return {
    }
  },
  watch: {
  },
  computed: {

  },
  created () {

  },
  mounted () {
  },
  methods: {
  }
}
</script>
<style lang="scss">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
body, div, span, header, footer, nav, section, aside, article, ul, dl, dt, dd, li, a, p, h1, h2, h3, h4,h5, h6, i, b, textarea, button, input, select, figure, figcaption {
    padding: 0;
    margin: 0;
    list-style: none;
    font-style: normal;
    text-decoration: none;
    border: none;
    font-family: "Microsoft Yahei",sans-serif;
    -webkit-tap-highlight-color:transparent;
    -webkit-font-smoothing: antialiased;
    &:focus {
        outline: none;
    }
}

input[type="button"], input[type="submit"], input[type="search"], input[type="reset"] {
    -webkit-appearance: none;
}

textarea { -webkit-appearance: none;}

html,body{
    height: 100%;
    width: 100%;
}

.clear:after{
    content: '';
    display: block;
    clear: both;
}

.clear{
    zoom:1;
}
.SdkMsgContent{
  .title{
    font-size: 23px;
    text-align: center;
    color: #303133;
    .iconfont{
      font-size: 20px;
    }
  }
  .msg_item{
    margin-top: 15px;
    .detail{
      border: 1px solid #d7d7d7;
      padding: 10px;
      border-radius: 8px;
      height: 150px;
      overflow: auto;
    }
  }
}
</style>
