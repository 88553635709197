import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routerView = {
  render (h) {
    return h('router-view')
  }
}
export const publicRoutes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/website/home.vue')
  }
]
const routes = [
  ...publicRoutes
]

const router = new VueRouter({
  routes
})

export default router
